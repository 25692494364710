<template>
  <div style="margin-left: 60px; margin-top: 25px">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-form-item
        :label="$t('UserManage.rolePage.RoleName') + '：'"
        prop="name"
      >
        <el-input
          v-model.trim="ruleForm.name"
          size="small"
          style="width: 250px"
        ></el-input>
      </el-form-item>
      <el-form-item :label="$t('UserManage.rolePage.describe') + '：'">
        <!-- 添加属性word-break:break-all;可以处理IE 添加属性word-wrap:break-word;可以处理火狐  中文和数字换行的问题-->
        <el-input
          type="textarea"
          v-model.trim="ruleForm.desc"
          style="width: 250px; word-break: break-all; word-wrap: break-word"
          :rows="4"
          show-word-limit
          maxlength="200"
        ></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('UserManage.rolePage.MenuPermissions') + '：'"
        required
      >
        <div class="box">
          <!-- :default-checked-keys="defaultChecked"  -->
          <el-tree
            :highlight-current="true"
            :current-node-key="currentNodeKey"
            ref="tree"
            :data="treeData"
            show-checkbox
            node-key="id"
            :default-expand-all="true"
            :props="defaultProps"
            :default-expanded-keys="expandedList"
            @check="check"
            @node-click="nodeClick"
          />
        </div>
      </el-form-item>
      <el-form-item>
        <!-- <el-button type="primary" size="small" @click="submit('ruleForm')">保存</el-button> -->
        <el-button
          type="primary"
          :loading="true"
          size="small"
          v-if="saveFlag == true"
          >{{ $t("UserManage.rolePage.Saving") }}</el-button
        >
        <el-button
          type="primary"
          :loading="false"
          size="small"
          @click="submit('ruleForm')"
          v-else
          >{{ $t("public.Save") }}</el-button
        >
      </el-form-item>
    </el-form>
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>

<script>
import zh from "../../../langs/zh.js";
import dialogInfo from "@/components/promptMessage/index";
export default {
  components: { dialogInfo },
  props: {
    editRoleObj: {
      type: Object,
      // 对象或数组默认值必须从一个工厂函数获取
      default: () => {
        return {};
      },
    },
  },
  data() {
    let validcodeName = (rule, value, callback) => {
      // ^\a-\z\A-\Z0-9\u4E00-\u9FA5
      // ^<>.,?;:'()!~%-_@#%/*""\s]+$"不允许输入特殊符号和字符
      let reg = /^[\u4e00-\u9fa5_a-zA-Z0-9]+$/;
      if (!reg.test(value)) {
        callback(new Error(this.$t("UserManage.rolePage.NotSupported")));
      } else {
        callback();
      }
    };
    return {
      saveFlag: false, //保存按钮是否为loading状态
      currentNodeKey: "",
      visibaelFlag: false, //提示消息默认--关闭
      titleInfo: "", // 提示框的提示语
      ruleForm: {
        name: "",
        desc: "",
      },
      checkedList: [],
      checkData: [], //勾选复选框数据
      checkDataId: [], //勾选复选框数据id
      treeData: [],
      //默认展开节点数据
      expandedList: [],
      defaultChecked: [], //默认勾选keys值
      checked: false, // 是否有选中
      rules: {
        name: [
          {
            required: true,
            message: this.$t("UserManage.rolePage.RoleNameVoid"),
            trigger: "blur",
          },
          { validator: validcodeName, trigger: "blur" },
        ],
        power: [
          {
            required: true,
            message: this.$t("UserManage.rolePage.MenuPermissVoid"),
            trigger: "blur",
          },
        ],
      },

      defaultProps: {
        children: "children",
        label: "label",
      },
      modelForm: {
        name: "",
        desc: "",
        treeData: "",
        id: "",
        groupId: "",
      },
      // 中文数据
      zhData: zh,
      // 中文字典
      zhMap: new Map(),
    };
  },
  mounted() {
    this.getMenuList(); //调用菜单列表
    this.ruleForm.name = this.editRoleObj.name;
    this.ruleForm.desc = this.editRoleObj.memo;
  },
  methods: {
    //限制输入特殊字符
    // btKeyUp(e) {
    //   e.target.value = e.target.value
    //     .replace(
    //       /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
    //       ''
    //     )
    //     .replace(/\s/g, '')
    // },
    async getMenuList() {
      var params = {};
      const res = await this.$axios.post(
        "/httpServe/menu/selectAllMenu",
        params,
        true
      );
      console.log(res.data, "菜单数据");
      // 中文字典
      for (const key of Object.keys(this.zhData)) {
        // console.log(key,'---keykey-------');
        // console.log(Object.keys(this.zhData),'---Object.keys()-------');
        if (key === "menu") {
          const item = this.zhData[key];
          // console.log(item,"item");
          for (const k2 of Object.keys(item)) {
            // console.log(item[k2],"item[k2]");
            this.zhMap.set(k2, item[k2]);
          }
        }
      }

      this.treeData = this.$common.combination(res.data, this.zhMap);
      console.log(this.treeData);
      this.treeData.forEach((item) => {
        this.expandedList.push(item.id);
      });
      this.checkArr(); //选中传参菜单接口
    },
    async checkArr() {
      var params = { roleId: this.editRoleObj.id };
      const res = await this.$axios.post(
        "/httpServe/role/selectList",
        params,
        true
      );
      res.data.map((i, n) => {
        var node = this.$refs.tree.getNode(i); //根据i获取tree中的节点
        if (node.isLeaf) {
          //设置某个节点的勾选状态
          this.$refs.tree.setChecked(node.data, true);
        } else {
          node.indeterminate = true;
          node.checked = true;
        }
      });
      res.data.forEach((item) => {
        this.checkedList.push(item);
      });
    },

    submit(formName) {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          //验证通过保存数据
          if (
            this.ruleForm.name.length > 0 &&
            this.$refs.tree.getCheckedKeys().length <= 0
          ) {
            this.$message({
              message: this.$t("UserManage.rolePage.MenuPermissVoid"),
              type: "error",
              offset: 100,
            });
          } else {
            let arr = [];
            this.$refs.tree
              .getCheckedNodes()
              .concat(this.$refs.tree.getHalfCheckedNodes())
              .forEach((i) => {
                arr.push(i.id);
              });
            this.modelForm.name = this.ruleForm.name;
            this.modelForm.desc = this.ruleForm.desc;
            this.modelForm.treeData = [...new Set(arr)];
            this.saveFlag = true;
            this.save(); //编辑保存接口方法
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    check(data, checked, indeterminate) {
      console.log(data, checked, "data");
      // this.checked = checked;
      // if (checked) {
      //   console.log(this.checkData, "this.checkData1111");
      //   this.checkData.push(data);
      //   this.checkDataId.push(data.id);
      // } else {
      //   console.log(this.checkData, "this.checkData2222");
      //   if (data.children) {
      //     data.children.forEach((item) => {
      //       this.checkedList.splice(this.checkedList.indexOf(item.id), 1);
      //     });
      //   } else {
      //     console.log(this.checkedList, "11111111111", data.id);
      //     this.checkedList.push(data.id);
      //   }
      //   const index = this.checkData.findIndex((d) => d.id === data.id);
      this.checkedList = checked.checkedKeys;
      this.checkData = checked.checkedNodes;
      this.checkDataId = checked.checkedKeys;
      // }
    },
    setChecked(key, checked, deep) {
      console.log(key, checked, deep);
    },
    nodeClick(data) {
      if (this.checkedList.includes(Number(data.id))) {
        this.checkedList.splice(this.checkedList.indexOf(Number(data.id)), 1);
        // 判断是否父节点
        if (data.children) {
          data.children.forEach((item) => {
            this.checkedList.splice(this.checkedList.indexOf(item.id), 1);
          });
        } else {
          this.checkedList.push(data.id);
        }
      } else if (data.children) {
        data.children.forEach((item) => {
          if (this.checkedList.includes(item.id)) {
            this.checkedList.splice(this.checkedList.indexOf(item.id), 1);
          } else {
            this.checkedList.push(item.id);
          }
        });
      } else {
        this.checkedList.push(data.id);
      }

      // 判断是否父节点
      if (data.children) {
        if (this.expandedList.includes(data.id)) {
          this.expandedList.splice(this.expandedList.indexOf(data.id), 1);
        } else {
          this.expandedList.push(data.id);
        }
      } else {
        this.expandedList = [];
      }
      this.$refs.tree.setCheckedKeys(this.checkedList.concat(this.checkDataId));
    },
    // 遍历树根据子节点的id查找父节点id,如果是根节点返回当前id
    findParent(data, id) {
      let parent = id;
      data.forEach((item) => {
        if (item.children) {
          if (item.children.find((child) => child.id === id)) {
            parent = item;
          } else {
            parent = this.findParent(item.children, id);
          }
        }
      });
      return parent;
    },
    // 根据父节点id查找所有子节点
    findChildren(data, id) {
      let children = [];
      data.forEach((item) => {
        if (item.children) {
          if (item.id === id) {
            children = item.children;
          } else {
            children = this.findChildren(item.children, id);
          }
        }
      });
      return children;
    },
    // 判断是否有子集
    isParent(data, id) {
      let isParent = false;
      data.forEach((item) => {
        if (item.children) {
          if (item.children.find((child) => child.id === id)) {
            isParent = true;
          } else {
            isParent = this.isParent(item.children, id);
          }
        }
      });
      return isParent;
    },

    // 弹框回调事件
    handleClose() {
      this.visibaelFlag = false; //提示消息  关闭
    },
    // 确定回调事件
    determine() {
      this.visibaelFlag = false; //提示消息  关闭
    },
    //保存接口
    async save() {
      var params = {
        ids: [this.editRoleObj.id.toString()],
        name: this.modelForm.name,
        memo: this.modelForm.desc,
        menuIds: this.modelForm.treeData,
      };
      // console.log(params, '编辑保存params-=-=')
      const res = await this.$axios.post(
        "/httpServe/role/update",
        params,
        true
      );
      if (res.code == 101) {
        this.visibaelFlag = true;
        this.titleInfo =
          this.$t("UserManage.rolePage.SaveFailed") + res.message;
      } else {
        this.$emit("eventSaveEdit", "1");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-form-item {
  margin-bottom: 17px !important;
}

.box {
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  padding: 5px 20px;
  width: 250px;
  height: 500px;
  overflow: auto;
}
</style>
<style>
.el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: #ffe6b0;
}
</style>
