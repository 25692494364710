<template>
  <div style="height: 100%">
    <!-- 查询框 -->
    <div style="height: 57px; line-height: 40px">
      <select-input
        :options="options"
        @selectSearchChange="selectSearchChange"
        @searchList="onSearchTable"
      ></select-input>
      <!-- <el-select v-model="value" size="mini" style="width: 120px" placeholder="用户名" @change="changeSele">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.label">
          </el-option>
        </el-select>
        <el-input v-model="searchTable" size="mini" type="text" :placeholder="`请输入${placeholder}`" style="width: 200px; margin-top: 2px" @click="onSearchTable" @keyup.enter.native="onSearchTable" @keyup.native="btKeyUp">
          <i @click="onSearchTable" slot="suffix" class="el-input__icon el-icon-search" style="cursor: pointer"></i>
        </el-input> -->
    </div>
    <el-row
      :gutter="16"
      style="margin-left: 0px; margin-right: 2px; height: 342px; overflow: auto"
    >
      <el-table
        stripe
        row-key="id"
        ref="multipleTable"
        fit
        class="sort_table"
        @select="handChange"
        @selection-change="handleSelectionChange"
        @select-all="selectAllChange"
        tooltip-effect="dark"
        :data="
          tableList.slice(
            (page.currentPage - 1) * page.pageSize,
            page.currentPage * page.pageSize
          )
        "
        style="width: 99%; font-size: 12px"
        :row-style="iRowStyle"
        :cell-style="iCellStyle"
        :header-row-style="iHeaderRowStyle"
        :header-cell-style="{
          background: '#f7f8fa',
          color: '#606266',
          padding: '0px',
        }"
      >
        <el-table-column
          type="selection"
          align="center"
          :reserve-selection="true"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="loginName"
          :label="$t('Delivery.distributionManagement.userName')"
          width="150"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="userName"
          :label="$t('Delivery.distributionManagement.fullName')"
          width="150"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column
          prop="groupFullName"
          :label="$t('Delivery.distributionManagement.department')"
          :show-overflow-tooltip="true"
        >
          <template v-slot="{ row }">
            <span v-if="row.groupFullName">
              {{ row.groupFullName.substr(groupFullNameShowByIndex) }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <div class="pageBox">
      <Pagination :page="page.currentPage" :limit="page.pageSize" :num="5" :showFirstPage="false" :showLastPage="false" :total="page.total" :pageSizes="[10, 20, 30, 40, 50]" v-on:handleChildGetList="handleSizeChange" />
      <!-- <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page.currentPage"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="page.pageSize"
        layout="total, sizes,jumper,slot"
        :total="page.total"
      >
        <span
          >{{ $t("public.total") }}{{ Math.ceil(page.total / page.pageSize)
          }}{{ $t("public.page") }}</span
        >
      </el-pagination> -->
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/pagination/page.vue";
import selectInput from "@/components/selectInput";
export default {
  components: { Pagination, selectInput },
  props: {
    leftIdArray: {
      type: Array,
      default: function () {
        return [];
      },
    },
    // 用户组关联用户回显v6.1.3
    AssociatedUsers: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      delFlag: 0,
      dataObj: {},
      selectVal: {
        deviceName: "",
        InputText: "",
      },
      page: {
        currentPage: 1,
        pageSize: 10,
        orderColume: "",
        orderRule: "",
        total: 0,
      },
      options: [
        {
          value: "loginName",
          label: "用户名",
        },
        {
          value: "userName",
          label: "姓名",
        },
      ],
      value: "用户名",
      userList: [], //获取vuex中按用户分配历史记录
      groupIds: "1", //左侧表格数据入参id
      checkNode: [], //勾选左侧表格的数据
      tableList: [], //表格数据
      seleShift: "loginName", // 选中下拉框转译
      placeholder: "用户名",
      selectedRows: [],
      searchTable: "", //表格中查询部门
      defaultNode: [], //默认选中的树节点
      checkNodeId: [], //勾选左侧表格的数据的id
      turnSearchId: "",
      multipleSelection: [], //表格多选数组
      groupFullNameShowByIndex: "",
    };
  },
  watch: {
    tableList: function (a) {
      this.delFlag = 1;
      // console.log(
      //   this.$store.state.assUserListB,
      //   "this.$store.state.assUserListB----------------"
      // );
      console.log("葛发财是个老六");
      // 用户组关联用户回显v6.1.3
      if (this.AssociatedUsers.length > 0) {
        this.userList = this.AssociatedUsers.concat(
          this.$store.state.assUserListB
        );
      } else {
        this.userList = this.$store.state.assUserListB;
      }
      this.$nextTick(() => {
        this.userList.map((vl) => {
          a.map((item) => {
            if (item.id == vl.id) {
              this.$refs.multipleTable.toggleRowSelection(item, true);
            }
          });
        });
      });
    },
    multipleSelection(newVal, oldVal) {
      this.$emit("groupCheckArray", this.uniqueByID(newVal));
    },
    total(newVal, oldVal) {
      this.page.total = newVal;
    },
    pageSize(newVal, oldVal) {
      this.page.pageSize = newVal;
    },

    // 开启深度监听
    deep: true,
  },
  created() {},
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
  },
  methods: {
    //限制输入特殊字符
    btKeyUp(e) {
      e.target.value = e.target.value
        .replace(
          /[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
          ""
        )
        .replace(/\s/g, "");
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },

    handleSizeChange(page, limit) {
      this.page.currentPage = page;
      this.page.pageSize = limit;
    },
    handleCurrentChange(val) {
      // console.log(val, "val----------------");
      this.page.currentPage = val;
      // this.onSearchTable(this.selectVal);
    },
    selectSearchChange(item) {
      this.options.forEach((k) => {
        if (item == k.label) {
          this.seleShift = k.value;
        }
      });
      this.placeholder = item;
    },
    //对象数组根据id进行去重
    uniqueByID(arr) {
      const set = new Set();
      return arr.filter((item) => {
        const id = item.id;
        if (!set.has(id)) {
          set.add(id);
          return true;
        }
        return false;
      });
    },
    //获取左侧的表格数据
    async getLeftTable(v) {
      this.page.currentPage = 1;
      this.turnSearchId = v;
      var params = {
        currentPage: this.page.currentPage,
        pageSize: 99999,
        orderColume: "u.updateTime",
        orderRule: "DESC",
        groupId: v,
        status: "",
        loginName: "",
        userName: "",
        phone: "",
      };
      const res = await this.$axios.post(
        "/httpServe/userInfo/getDeliveryUser",
        params,
        true
      );
      this.tableList = res.data.content;
      this.page.total = res.data.total;
    },
    //表格查询部门
    async onSearchTable(val) {
      this.page.currentPage = 1;
      var params = {
        currentPage: this.page.currentPage,
        pageSize: 99999,
        orderColume: "u.updateTime",
        orderRule: "DESC",
        groupId: this.turnSearchId,
        status: "",
        loginName: val
          ? val.deviceName == "loginName"
            ? val.InputText
            : ""
          : "",
        userName: val
          ? val.deviceName == "userName"
            ? val.InputText
            : ""
          : "",
        phone: "",
      };
      const res = await this.$axios.post(
        "/httpServe/userInfo/getDeliveryUser",
        params,
        true
      );
      this.tableList = res.data.content;
      this.page.total = res.data.total;
    },

    //表格多选
    handleSelectionChange(val) {
      console.log(val, "val表格多选");
      let uniqueArr = [];
      let idMap = new Map();

      for (let obj of val) {
        let id = obj.id;
        if (!idMap.has(id)) {
          idMap.set(id, true);
          uniqueArr.push(obj);
        }
      }

      console.log(this.dataObj, this.delFlag, "this.dataObj");
      if (this.delFlag !== 0) {
        uniqueArr.forEach((item, index) => {
          if (item.id == this.dataObj.id) {
            this.$refs.multipleTable.toggleRowSelection(item, false);
            uniqueArr.splice(index, 1);
          }
          this.delFlag = 0;
        });
      }

      console.log(uniqueArr, "uniqueArr表格多选");
      this.multipleSelection = uniqueArr;
      this.$store.commit("assUser_multipleA", uniqueArr);
    },
    //表格全选事件
    selectAllChange(selection) {
      this.delFlag = 0;
      this.dataObj = {};
      console.log(selection, "selection表格全选事件");
      this.multipleSelection = selection;
      this.$store.commit("assUser_multipleA", selection);
      this.selectedRows = selection;
      const allRows = this.tableList;
      const selectedRows = this.selectedRows;
      const unselectedRows = allRows.filter(
        (row) => !selectedRows.includes(row)
      );
      // 在这里可以获取取消选中的行，unselectedRows 就是取消选中的行数组
      console.log(unselectedRows, "获取取消选中的行");
      unselectedRows.forEach((i, v) => {
        this.$store.commit("assUser_del", i.id);
      });
      // if (selection.length == 0) {
      //   for (let i = 0; i < this.tableList.length; i++) {
      //     this.$store.commit("assUser_del", this.tableList[i].id);
      //   }
      // }
    },
    //勾选时触发的事件
    handChange(selection, row) {
      console.log(selection, this.delFlag, "selection");
      console.log(row, "要判断是选中还是取消row");
      // 判断是选中还是取消 true就是选中，0或者false是取消选中
      // const checkedVal = selection.length && selection.find(x => x.id === row.id)
      let checkedVal = selection.length && selection.indexOf(row) !== -1;
      console.log(checkedVal, "选中还是取消row");
      if (!checkedVal) {
        this.$store.commit("assUser_del", row.id);
      } else {
        this.dataObj = {};
      }
      console.log(
        this.multipleSelection,
        "this.multipleSelection勾选时触发的事件362"
      );
    },
    // 删除 选中表格数据
    deleteData(v) {
      this.delFlag = 1;
      this.dataObj = v;
      for (let i = 0; i < this.tableList.length; i++) {
        const item = this.tableList[i];
        if (item.id == v.id) {
          // this.tableList.splice(i, 1);
          this.$refs.multipleTable.toggleRowSelection(item, false);
          this.$store.commit("assUser_del", item.id);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
//分页
.pageBox {
  margin-top: 10px;
}

// 暂无数据

::v-deep .el-table__empty-block {
  width: 20% !important;
}

::v-deep .el-table__row > td {
  border: none;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table th.is-leaf {
  border: none;
}

::v-deep .el-select-style .el-input__inner {
  border: 0;
  height: 28px !important;
  line-height: 28px !important;
}

::v-deep .el-input--small .el-input__inner {
  height: 28px !important;
  line-height: 28px !important;
}

::v-deep .el-input--suffix .el-input__inner {
  height: 24px !important;
  line-height: 24px !important;
}

::v-deep .el-select-style {
  height: 26px !important;
  line-height: 26px !important;
  position: absolute;
  left: 2px !important;
  top: 8px !important;
  border: none;
  border-right: 1px solid #c0c4cc !important;
  margin-top: 0px;
  margin-right: 0px;
}

::v-deep .el-col-style .el-button--small {
  height: 28px !important;
  margin-top: 6px !important;
}

::v-deep .mt28 {
  padding: 15px 10px !important;
}

::v-deep .el-select-style .el-input--small .el-input__icon {
  line-height: 27px !important;
}
::v-deep .el-input--small {
    font-size: 12px;
}
</style>
