<template>
  <div>
    <!-- 面包屑 -->
    <el-row class="formBox" v-if="flag == 1">
      <el-row :gutter="4" class="elRow" style="display: flex">
        <el-col :span="3">
          <el-input
            size="small"
            v-model.trim="page.title"
            :placeholder="$t('UserManage.userGroup.placeholder')"
            @keyup.enter.native="search"
            @keyup.native="btKeyUp"
          ></el-input>
        </el-col>
        <el-col :span="3">
          <el-button size="small" type="primary" @click="search">{{
            $t("public.Inquire")
          }}</el-button>
        </el-col>
      </el-row>

      <el-row :gutter="4" class="elRow" style="display: flex">
        <el-col :span="3">
          <el-button-group class="butGruop">
            <!-- 新增 -->
            <el-button size="small" type="primary" @click="addNew">{{
              $t("public.New")
            }}</el-button>
            <!-- 删除 -->
            <el-button
              size="small"
              ref="button_del"
              :type="typeInfo"
              :disabled="!hasSeleted"
              @click="delMuch(delArr)"
              >{{ $t("public.Delete") }}</el-button
            >
          </el-button-group>
        </el-col>
      </el-row>
      <el-row :gutter="4" class="elRow">
        <el-table
          fit
          stripe
          size="mini"
          ref="multipleTable"
          :row-key="getRowKeys"
          :data="tableData"
          tooltip-effect="dark"
          style="width: 100%"
          @select-all="selectAll"
          @select="checkChange"
          @sort-change="sort"
          @selection-change="handleSelectionChange"
          :default-sort="{ prop: 'update_time', order: 'descending' }"
          :row-style="iRowStyle"
          :cell-style="iCellStyle"
          :header-row-style="iHeaderRowStyle"
          :header-cell-style="{
            background: '#f7f8fa',
            color: '#606266',
            padding: '0px',
          }"
          class="tableClass"
        >
          <el-table-column
            type="selection"
            width="60"
            align="center"
            :reserve-selection="true"
          >
          </el-table-column>
          <el-table-column
            prop="name"
            :label="$t('UserManage.userGroup.name')"
            sortable="custom"
            :show-overflow-tooltip="true"
            width="180"
          >
          </el-table-column>
          <el-table-column
            prop="memo"
            :label="$t('UserManage.rolePage.describe')"
            :show-overflow-tooltip="true"
            width="150"
          >
          </el-table-column>
          <el-table-column
            prop="groupFullName"
            :label="$t('public.Section')"
            :show-overflow-tooltip="true"
            width="180"
          >
            <template v-slot="{ row }">
              <span v-if="row.groupFullName">{{
                row.groupFullName.substr(groupFullNameShowByIndex)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="userCount"
            :label="$t('UserManage.userGroup.userCount')"
            :show-overflow-tooltip="true"
            width="100"
          >
            <template v-slot="{ row }">
              <span
                v-if="row.userCount"
                @click="getUsers(row)"
                style="cursor: pointer; color: #d38a08"
              >
                {{ row.userCount }}
              </span>
              <span v-else>
                {{ row.userCount }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            prop="update_time"
            :label="$t('public.ModificationTime')"
            sortable="custom"
            :show-overflow-tooltip="true"
            width="180"
          >
            <template v-slot="{ row }">
              {{ row.updateTime | dateformat }}
            </template>
          </el-table-column>
          <el-table-column :label="$t('public.Controls')">
            <template v-slot="{ row }">
              <span class="action_icon">
                <img
                  @click="edit(row)"
                  src="../../../assets/icon_write.png"
                  :title="$t('public.Editor')"
                  alt=""
                />
              </span>

              <span class="action_icon">
                <img
                  @click="details(row.id)"
                  src="../../../assets/icon_del.png"
                  :title="$t('public.Delete')"
                  alt=""
                />
              </span>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页-->
        <pagination
          :page="page.currentPage"
          :limit="page.pageSize"
          :total="page.total"
          :pageSizes="[10, 20, 30, 40]"
          v-on:handleChildGetList="handleParentGetList"
        ></pagination>
      </el-row>
    </el-row>
    <!-- 新增页面 -->
    <addUserGroup
      v-if="flag == 2"
      :editObj="editFrom"
      @eventSave="saveFun"
    ></addUserGroup>
    <editRole
      v-if="flag == 3"
      :editRoleObj="editRoleFrom"
      @eventSaveEdit="editRoleSaveFun"
    >
    </editRole>
    <!-- 关联用户 -->
    <elDialog
      :title="$t('UserManage.userGroup.userCount')"
      :num="'470px'"
      :width="'900px'"
      :dialogVisible="statusVisible"
      v-if="statusVisible"
      @handleClose="handleClose"
      @heightCustom="heightCustom"
    >
      <associatingUsers :rowId="rowId" />
    </elDialog>
    <dialog-info
      :dialogInfo="visibaelFlag"
      @handleClose="handleClose"
      @determine="determine"
      :titleInfo="titleInfo"
      :width="'240px'"
    />
  </div>
</template>

<script>
import associatingUsers from "./compontesUser/associatingUsers.vue";
import addUserGroup from "./compontesUser/addUserGroup.vue";
import editRole from "../components/editRole.vue";
import pagination from "@/components/pagination/page.vue";
import dialogInfo from "@/components/promptMessage/index";
import elDialog from "@/components/elDialog"; //详情弹框
export default {
  components: {
    addUserGroup,
    editRole,
    pagination,
    dialogInfo,
    associatingUsers,
    elDialog,
  },
  data() {
    return {
      typeInfo: "info", // 删除按钮默认灰色
      rowId: "", //关联用户的id
      statusVisible: false, //关联用户弹框框默认关闭
      flag: 1, //页面切换标识
      page: {
        total: 0, //返回数据总条数
        title: "", //查询输入框
        pageSize: 10, //每页显示条数
        orderRule: "DESC", //排序规则ASC、DESC
        currentPage: 1, //当前页码
        orderColume: "update_time", //排序字段
      },
      delArr: [], //存放要删除的数据
      checked: false, // 全选框双向绑定
      RoleSave: {}, //子组件传给父组件的表单数据
      RoleFrom: {},
      tableData: [], //表格数据
      titleInfo: "", // 提示框的提示语
      isCheckShow: false, // 全选弹框
      visibaelFlag: false, //提示消息默认--关闭
      editFrom: {}, //父组件传给子组件表单数据 this.$t('UserManage.rolePage.ReminderInfo')
      groupFullNameShowByIndex: "",
    };
  },
  computed: {
    hasSeleted() {
      return this.delArr && this.delArr.length;
    },
  },
  mounted() {
    this.groupFullNameShowByIndex = sessionStorage.getItem(
      "groupFullNameShowByIndex"
    );
    this.getTableList();
  },
  methods: {
    //限制输入特殊字符
    btKeyUp(e) {
      e.target.value = e.target.value
        .replace(
          /[`~!@#$%^&*()_\-+=<>?:"{}|,/;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘’，。、]/g,
          ""
        )
        .replace(/\s/g, "");
    },
    getRowKeys(row) {
      return row.id;
    },
    //表格行高
    iRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iHeaderRowStyle: function ({ row, rowIndex }) {
      return "height:30px";
    },
    iCellStyle: function ({ row, column, rowIndex, columnIndex }) {
      return "padding:3px 0px";
    },
    handleClose() {
      this.statusVisible = false; //关联弹框
      this.visibaelFlag = false; //提示消息  关闭
    },
    //确定回调事件
    determine() {
      this.visibaelFlag = false; //提示消息  关闭
    },
    //排序
    sort(column) {
      console.log(column.order, column.prop);
      if (column.order === "ascending") {
        this.page.orderRule = "ASC";
      } else if (column.order === "descending") {
        this.page.orderRule = "DESC";
      }
      this.page.orderColume = column.prop;
      //调用列表方法
      this.getTableList();
    },
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.page.currentPage = page;
      this.page.pageSize = limit;
      //调用列表方法
      this.getTableList();
    },
    // 删除
    handleSelectionChange(obj) {
      this.multipleSelection = obj;
      this.delArr = obj;
      this.typeInfo = "primary";
      if (this.delArr.length == 0) {
        this.typeInfo = "info";
      }
    },

    //获取表格数据
    async getTableList() {
      this.$store.state.assUserListA = [];
      this.$store.state.assUserListB = [];
      var params = {
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        name: this.page.title,
      };

      const res = await this.$axios.post(
        "/httpServe/userInfoGroup/getDataInfo",
        params,
        true
      );

      this.tableData = res.data.content;
      this.page.total = res.data.total;
    },
    //查询
    async search() {
      if (this.page.title.length > 0) {
        this.page.currentPage = 1;
      }
      var params = {
        currentPage: this.page.currentPage,
        pageSize: this.page.pageSize,
        orderColume: this.page.orderColume,
        orderRule: this.page.orderRule,
        name: this.page.title,
      };

      const res = await this.$axios.post(
        "/httpServe/userInfoGroup/getDataInfo",
        params,
        true
      );

      this.tableData = res.data.content;
      this.page.total = res.data.total;
    },

    //新增按钮
    addNew() {
      this.flag = 2;
      this.editFrom = {};
    },
    //新增表格数据保存
    async saveFun(v) {
      this.flag = v;
      this.page.title = "";
      this.getTableList(); //新增保存之后在调用表格数据
      this.$message({
        message: this.$t("public.SuccessfullySaved"),
        type: "success",
        offset: 100,
      });
    },
    //编辑回显
    edit(row) {
      this.flag = 2;
      this.editFrom = { ...row };
      console.log(this.editFrom, "编辑回显");
    },
    //编辑
    editRoleSaveFun(v) {
      this.flag = v;
      this.page.title = "";
      this.getTableList(); //编辑保存之后在调用表格数据
      this.$message({
        message: this.$t("public.SuccessfullySaved"),
        type: "success",
        offset: 100,
      });
    },
    //单个删除
    details(id) {
      var params = {
        ids: [id],
      };
      this.$confirm(
        this.$t("UserManage.userGroup.delMessage"),
        this.$t("UserManage.rolePage.ReminderInfo"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      )
        .then(async () => {
          const res = await this.$axios.post(
            "/httpServe/userInfoGroup/delete",
            params,
            true
          );
          console.log(res.data, "res.data单个删除");
          if (res.data == -2) {
            this.$message({
              message: this.$t("UserManage.userGroup.delError"),
              type: "error",
              offset: 100,
            });
          }
          if (res.data == 0) {
            // debugger;
            this.$message({
              message: this.$t("public.SuccessfullyDeleted"),
              type: "success",
              offset: 100,
            });
            this.getTableList();
            this.page.currentPage = 1;
            this.$refs.tableData.clearSelection();
          }
        })
        .catch(() => {
          return false;
        });
    },
    //多选删除
    delMuch(obj) {
      var aa = [];
      obj.forEach((item) => {
        aa.push(item.id.toString());
      });
      var params = {
        ids: aa,
      };
      this.$confirm(
        this.$t("UserManage.userGroup.delMessage"),
        this.$t("UserManage.rolePage.ReminderInfo"),
        {
          confirmButtonText: this.$t("public.Verify"),
          cancelButtonText: this.$t("public.Cancel"),
          type: "warning",
          customClass: "persdsd",
        }
      ).then(async () => {
        const res = await this.$axios.post(
          "/httpServe/userInfoGroup/delete",
          params,
          true
        );
        if (res.data == -2) {
          this.$message({
            message: this.$t("UserManage.userGroup.delError"),
            type: "error",
            offset: 100,
          });
        } else {
          this.$message({
            message: this.$t("public.SuccessfullyDeleted"),
            type: "success",
            offset: 100,
          });
          this.page.currentPage = 1;
          this.$refs.multipleTable.clearSelection();
          this.getTableList();
        }
      });
    },
    //全选
    selectAll(selection) {
      console.log(selection);
      if (selection.length > 0) {
        this.$refs.button_del.type = "primary";
        this.$refs.button_del.disabled = false;
      } else {
        this.$refs.button_del.type = "info";
        this.$refs.button_del.disabled = true;
      }
    },
    // 复选框勾选
    checkChange(selection, row) {
      let arr1 = [];
      if (selection.length > 0) {
        this.$refs.button_del.type = "primary";
        this.$refs.button_del.disabled = false;
        selection.forEach((item) => {
          arr1.push(item.id);
          //去重
          this.delArr = [...new Set(arr1)];
        });
      } else {
        this.$refs.button_del.type = "info";
        this.$refs.button_del.disabled = true;
      }
    },
    //关联用户
    getUsers(row) {
      console.log("====================================");
      console.log(row);
      console.log("====================================");
      this.rowId = row.id;
      this.statusVisible = true;
    },
    // 高度
    heightCustom() {
      this.height.height = this.num;
    },
  },
};
</script>

<style lang="scss" scoped>
.formBox {
  width: 100%;
  height: 100%;
  padding: 10px 10px 0px 10px !important; //页面最外层上右左10
  min-width: 1500px;
  font-family: Microsoft YaHei;
}
.elRow {
  margin-bottom: 10px;
}
::v-deep .el-table__row > td {
  border: none;
}
::v-deep .el-table::before {
  height: 0px;
}
::v-deep .el-table th.is-leaf {
  border: none;
}

// 操作按钮
.butGruop > .el-button:not(:last-child) {
  margin-right: 0px !important;
}
</style>
