<template>
  <div>
    <el-input
      size="small"
      style="width: 180px; margin-bottom: 10px"
      v-model="input"
      @keyup.enter.native="search"
      :placeholder="$t('UserManage.userGroup.placeholderName')"
      ><i
        @click="search"
        slot="suffix"
        class="el-input__icon el-icon-search"
        style="cursor: pointer"
      ></i
    ></el-input>
    <div class="bigBox">
      <el-table
        :cell-style="{ padding: '3px 0px' }"
        :header-row-style="{ height: '30px' }"
        size="mini"
        stripe
        ref="multipleTable"
        :data="tableData"
        class="tableClass"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column prop="loginName" :label="$t('public.Username')" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column prop="userName" :label="$t('public.Name')" :show-overflow-tooltip="true">
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="groupFullName"
          :label="$t('public.Section')"
        >
        <template v-slot="{ row }">
              <span v-if="row.groupFullName">{{
                row.groupFullName.substr(groupFullNameShowByIndex)
              }}</span>
            </template>
        </el-table-column>
        <el-table-column prop="statusStr" :label="$t('UserManage.user.UserList.UserStatus')">
          <template v-slot="{ row }">
            <template v-if="row.statusStr == $t('UserManage.userGroup.normal')">
              <span style="color: #2ba245">{{ row.statusStr }}</span>
            </template>
            <template v-if="row.statusStr == $t('UserManage.userGroup.locked')">
              <span style="color: orange">{{ row.statusStr }}</span>
            </template>
            <template v-if="row.statusStr == $t('UserManage.userGroup.notUsed')">
              <span style="color: #ff0000">{{ row.statusStr }}</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          :show-overflow-tooltip="true"
          prop="updateTime"
          :label="$t('public.ModificationTime')"
          width="180"
        >
          <template v-slot="{ row }">
            <span v-if="row.updateTime == null"></span>
            <span v-else>{{ row.updateTime }}</span>
          </template>
        </el-table-column>
      </el-table>
      <!-- <p style="margin-top: 10px">
        {{ $t("public.total") }}<b>{{ total }}</b
        >{{ $t("UserManage.adminPage.Records") }}
      </p> -->
      <!-- 分页-->
      <pagination
        style="margin-right: 10px"
        :page="currentPage"
        :limit="pageSize"
        :total="total"
        :pageSizes="[10, 20, 30, 40]"
        v-on:handleChildGetList="handleParentGetList"
      ></pagination>
    </div>
  </div>
</template>

<script>
import pagination from "@/components/pagination/page.vue";
export default {
  components: {
    pagination,
  },
  props: {
    rowId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      input: "", //查询输入框
      tableData: [], //表格数据
      total: 0, //返回数据总条数
      pageSize: 10, //每页显示条数
      currentPage: 1, //当前页码
      groupFullNameShowByIndex: "",
    };
  },
  created() {
    this.groupFullNameShowByIndex = sessionStorage.getItem("groupFullNameShowByIndex");
    this.getListData();
  },
  mounted() {},
  methods: {
    // 响应分页组件查询事件
    handleParentGetList(page, limit) {
      this.currentPage = page;
      this.pageSize = limit;
      //调用列表方法
      this.getListData();
    },
    //获取列表
    async getListData() {
      var param = {
        id: this.rowId,
        loginName:this.input,
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      };
      console.log(param, "param获取列表");
      const res = await this.$axios.post(
        "/httpServe/userInfoGroup/getUserInfo",
        param,
        true
      );
      console.log(res, "res");
      this.tableData = res.data.content;
      this.total = res.data.total;
    },
    //查询
    search() {
      if (this.input.length > 0) {
      //   let arr = this.tableData;
      //   let list = arr.filter(
      //     (item) => item.loginName.toLowerCase().indexOf(this.input.toLowerCase()) !== -1
      //   );
      //   this.tableData = list;
      //   this.total = res.data.total;
      // } else {
        this.currentPage = 1;
      }
        this.getListData();
      // if (!this.input) {
      //   this.getListData(this.deviceCount)
      // }
    },
  },
};
</script>

<style lang="scss" scoped></style>
